<template>
  <v-card>
    <v-card-title>Notifications</v-card-title>
    <div>
      <v-card-text>
        <v-row>
          <v-col
            cols="6"
          >
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Search"
              single-line
              hide-details
              dense
              outlined
              @keyup="searchData"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- Table -->
      <div class=" col-12">
        <div class=" col-5 ">
        </div>

        <div class="text-right col-2 ">
          <v-btn
            color="primary"
            block
            @click="openAddForm"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon> Send Notification
          </v-btn>
        </div>
      </div>
      <br>

      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :options.sync="options"
        :server-items-length="totalItems"
        class="table-kitchen-sink"
      >
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import { mdiMagnify, mdiPlus } from '@mdi/js'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
	components: {},
	data() {
		return {
			headers: [
				{ text: 'Title', value: 'notification_title' },
				{ text: 'Message', value: 'notification_message' },
				{ text: 'Nationality', value: 'country_name' },
				{ text: 'Delivery Date & Time', value: 'delivery_time_saudi' },
				{ text: 'Send Status', value: 'notification_send_status' },
				{ text: 'Created Date', value: 'created_at' },
			],
			icons: { mdiMagnify, mdiPlus },
		}
	},
	computed: {
		...mapGetters('notification', ['items', 'totalItems']),

		options: {
			get() {
				return this.$store.getters['notification/options']
			},
			set(value) {
				this.$store.commit('notification/SET_OPTIONS', value)
			},
		},
		search: {
			get() {
				return this.$store.getters['notification/search']
			},
			set(value) {
				this.$store.commit('notification/SET_SEARCH', value)
			},
		},

	},
	watch: {
		options() {
			this.datatable()
		},
	},

	methods: {
		searchData: _.debounce(function fn() {
			this.datatable()
		}, 400),

		datatable() {
			console.log('4444')
			this.$store.dispatch('notification/datatable')
		},
		openAddForm() {
			this.$router.push('/notification/create')
		},

	},
}
</script>

<style scoped>

</style>
